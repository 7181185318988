import PropTypes from 'prop-types'
import {
    Container,
    Header,
    Image,
  } from 'semantic-ui-react'

/* Heads up!
 * Home uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
const Home = ({ mobile }) => (
    <Container>
      <Header
        as='h1'
        content='Welcome to our site!'
        style={{
          fontSize: mobile ? '1.5em' : '3em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: '1em',
        }}
      />
      <Header
        as='h2'
        content="It's not much to look at, but check out this mugshot of Della."
        style={{
          fontSize: mobile ? '1em' : '2em',
          fontWeight: 'normal',
          marginTop: '0.5em',
        }}
      />
      <Image src='/images/della_mugshot.jpg' size='large' wrapped />
    </Container>
  );
  
  Home.propTypes = {
    mobile: PropTypes.bool,
  };

  export default Home;