/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ROUTES, LINKS } from './nav'
import { Link, Switch, Route, useLocation } from 'react-router-dom'
import {
  Container,
  Icon,
  Header,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'

const pageHeader = "The Dunn Family";

function getMenuItems(desktop, path, clickHandler) {
  let menuItems = [];
  ROUTES.forEach(route => {
      const props = {
        position: menuItems.length === 0 && desktop ? 'right' : undefined,
        active: path === route.path
      };
      menuItems.push(
          <Menu.Item key={route.path} as={Link} to={route.path} onClick={clickHandler} {...props}>
              {route.name}
          </Menu.Item>
      );
  });
  LINKS.forEach(link => {
      menuItems.push(
          <Menu.Item key={link.href} as='a' href={link.href} target='_blank'>
              <Icon name={link.icon} /> {link.name}
          </Menu.Item>
      );
  });
  return menuItems;
}

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer path={useLocation().pathname}>{children}</DesktopContainer>
    <MobileContainer path={useLocation().pathname}>{children}</MobileContainer>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false,
    };
  }

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props;
    const { fixed } = this.state;
    const fixedMenuStyle = {
      padding: '0 ' + (window.innerWidth - 1127) / 2 + 'px'
    }
    console.log(fixedMenuStyle);
    return (
      <Media greaterThan='mobile'>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign='center'
            style={{ padding: '1em 0em' }}
            vertical
          >
            <Container>
              <Menu
                  fixed={fixed ? 'top' : null}
                  inverted={!fixed}
                  pointing={!fixed}
                  secondary={!fixed}
                  style={fixed ? fixedMenuStyle : undefined}
              >
                <Header as='h2' inverted={!fixed} style={{whiteSpace: 'nowrap', marginBottom: 0, paddingTop: '5px'}}>
                  {pageHeader}
                </Header>
                <Container fluid>
                    {getMenuItems(true, this.props.path)}
                </Container>
              </Menu>
            </Container>
          </Segment>
        </Visibility>
        <Container textAlign='center'>
          {children}
        </Container>
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })
  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            {getMenuItems(false, this.props.path, this.handleSidebarHide)}
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                  <Menu.Item header position='right'>
                    {pageHeader}
                  </Menu.Item>
                </Menu>
              </Container>
            </Segment>
            <Container textAlign='center'>
              {children}
            </Container>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const App = () => {
  let routes = [];
  ROUTES.forEach(route => {
    routes.push(
      <Route key={route.path} path={route.path}>
        {route.component}
      </Route>
    );
  });
  return (
    <ResponsiveContainer>
      <Switch>
        {routes}
      </Switch>
    </ResponsiveContainer>
  );
}

export default App